import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";

import { useViewport } from "../../hooks/useViewport";

import Code from '../../assets/icons/code.svg'

const EmbedCodeModal = ({ code, show, onClose }) => {
    const [isCopied, setIsCopied] = useState(false)

    const user = useSelector((state) => state.app.user)

    const codeRef = useRef(null)
    const rootRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const imagePath = code.image.replace("https://storage.googleapis.com/qr-code-gen/qrcode_images/", "").split('?')[0]
    const content = `<div style="width:fit-content;display:flex;flex-direction:column;align-items:end;"><a href="${code.type === 'static' && code.data.kind === "website_url" ? code.data.content : code.type === 'static' && code.data.kind !== "website_url" ? 'https://qrcodeveloper.com' : `https://qrcodeveloper.com/code/${code.public_id}`}" style='cursor:pointer;display:block'><img style="width:350px;box-shadow: 0 0 13px #00000040; border-radius: 16px;" src="https://qrcodeveloper.com/qr/images/${imagePath}" alt='Create qr code for free'/></a><a href='https://qrcodeveloper.com' style='cursor:pointer;color:#9747FF;text-decoration:none;font-size:14px;font-weight:500;margin-top:5px;'>QR Code Developer</a><img id="qr-tracker" style="display:none" alt='Create qr code for free' /></div><script>(function() {var tracker = document.getElementById('qr-tracker'); var baseUrl = "https://api.qrcodeveloper.com/api/core/embed-code/track?user_id=${user?.uid}&code_id=${code.id}";try { var fullUrl = encodeURIComponent(window.location.href); baseUrl += '&full_path=' + fullUrl; } catch(e) { baseUrl += '&full_path=Not Available' }; tracker.setAttribute('src', baseUrl);})();</script>`

    const handleCopyCode = () => {
        window.navigator.clipboard.writeText(codeRef.current.value)
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000)
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            position='center'
            onClose={onClose}
            style={{ height: '100vh' }}
            className='embed-code-modal'
            root={rootRef.current ?? undefined}
        >
            <Modal.Body className='w-full bg-[#EDF3F9] rounded-[24px] p-0'>
                <div
                    onClick={onClose}
                    className='absolute w-[32px] h-[32px] bg-[#FFFFFF] right-[-40px] top-[0] rounded-full flex items-center justify-center cursor-pointer mobile:right-[16px] mobile:top-[16px] mobile:bg-[#1B003E]'
                >
                    <IoCloseOutline
                        size={20}
                        color={isMobile ? '#FFFFFF' : '#141315'}
                    />
                </div>
                <div className="p-[24px] flex flex-col w-full gap-[16px] bg-[#FFFFFF] rounded-[24px]">
                    <div className="flex flex-col gap-[8px]">
                        <img className="w-[30px]" src={Code} alt="" />
                        <h5 className="text-[23px] leading-[28px] tracking-[-0.02em] text-[#141315] font-bold">
                            {t("embedYourQRCodeOnYourWebsite")}
                        </h5>
                        <p className="text-[#7D8897] text-[16px] leading-[22px]">
                            {t("youCanEmbedYourQRCodeOnYourWebsiteUsingTheHTMLCodeBelow")}
                        </p>
                    </div>
                    <div className="h-[24px] border-solid border-[1px] border-[#E2E8F0] px-[6px] rounded-[4px] w-fit text-[#141315] text-[14px] leading-[17px] flex items-center justify-center">
                        {t("commercialUsageIsAllowed")}
                    </div>
                    <span className="w-full h-[1px] bg-[#E2E8F0]"></span>
                    <button onClick={handleCopyCode} className="bg-[#9747FF] h-[40px] rounded-lg hover:bg-[#7f2fe7] transition-colors duration-300 text-white font-semibold text-[16px] leading-[23px] items-center justify-center gap-[8px] hidden mobile:flex">
                        <FiCopy size={18} />
                        {isCopied ? t("copied") : t("copy")}
                    </button>
                    <div className="flex flex-col gap-[8px]">
                        <h6 className="font-semibold text-[#141315] text-[16px] leading-[23px]">
                            {t("qrHtmlCode")}
                        </h6>
                        <div className="bg-[#EDF3F9] rounded-lg p-[8px] break-words">
                            {content}
                        </div>
                    </div>
                    <button onClick={handleCopyCode} className="bg-[#9747FF] h-[40px] rounded-lg hover:bg-[#7f2fe7] transition-colors duration-300 text-white font-semibold text-[16px] leading-[23px] flex items-center justify-center gap-[8px] mobile:hidden">
                        <FiCopy size={18} />
                        {isCopied ? t("copied") : t("copy")}
                    </button>
                    <input
                        ref={codeRef}
                        readOnly
                        value={content}
                        className="hidden"
                        type="text"
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default EmbedCodeModal;
